import { Component , ChangeDetectorRef} from '@angular/core';
import { Router } from '@angular/router';

import {Platform} from '@ionic/angular';

import {Amplify, Auth, Hub} from 'aws-amplify';
import awsmobile from '../aws-exports';

//import { I18n } from 'aws-amplify';
import { I18n } from '@aws-amplify/core';
import { zip } from 'rxjs';
const dict = {
  'it': {
      'Sign In': "Login",
      'Sign in': "Login",
      'Create Account': "Registrati",
      'Confirm Password': "Conferma Password",
      'Forgot your password? ': "Password dimenticata?"
  }
};

I18n.putVocabularies(dict);
I18n.setLanguage('it');


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
 
  

  public utentecorrente: any=null;
  
  public appPages = [{ title: 'Home', url: '/home', icon: 'home' }];
  
  public loginMenu={ title: 'Accedi/Registrati', url: '/loginpage', icon: 'person' };

  constructor(
    private platform: Platform,
    private ref: ChangeDetectorRef,
    private router: Router
  ) {
    this.initializeApp();
  }
  getPages() {
    var x=[];
    x.push(this.getLoginMenu());
    for(var y in this.appPages) {
      x.push(this.appPages[y]);
    }
    return x;
  }
  getLoginMenu() {
    return this.loginMenu;
  }
  doSignout() {
    Auth.signOut();
    this.router.navigate(['/home']);
  }
  updateCurrentUser() {
    this.utentecorrente=null;
    this.loginMenu={ title: 'Accedi/Registrati', url: '/loginpage', icon: 'person' };     
    Auth.currentAuthenticatedUser().then(user => 
      {
        this.utentecorrente=user;
        this.loginMenu={ title: 'Logout', url: '/home', icon: 'person' };
        console.log(user);
      }
    ).catch(e => {
     //do nothing. it's expected if user is not autenticated.
      console.log(e);
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      Amplify.configure(awsmobile);
      this.updateCurrentUser();
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'signIn':
              console.log('user signed in');
              this.router.navigate(['/home']);
              break;
          case 'signUp':
              console.log('user signed up');
              break;
          case 'signOut':
              console.log('user signed out');
              break;
          case 'signIn_failure':
              console.log('user sign in failed');
              break;
          case 'configured':
              console.log('the Auth module is configured');
        }
        this.updateCurrentUser();
      });
    });
  }


}
