import { NgModule } from '@angular/core';
import { NgAudioRecorderService, OutputFormat } from './ng-audio-recorder.service';
import { Component } from '@angular/core';


@NgModule({
  declarations: [],
  imports: [
  ],
  exports: [],
  providers: [
    NgAudioRecorderService
  ]
})
export class NgAudioRecorderModule { }